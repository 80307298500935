import React from "react";
import style from './index.module.sass'
import {api} from "../../../../../config";

const ActiveImageComponent = ({images, primaryImage}) => (
    <div className={style.box}>
        {
            images.length > 0
            && <div className="imageAspectRatio">
                <img src={api + primaryImage.path} alt=""/>
            </div>
        }
    </div>
)

export default ActiveImageComponent